import abc from "../components/assets/networks/abc.png";
import cbs from "../components/assets/networks/cbs.png";
import disney from "../components/assets/networks/disney.png";
import fox from "../components/assets/networks/fox.png";
import hbo from "../components/assets/networks/hbo.png";
import hulu from "../components/assets/networks/hulu.png";
import lifetime from "../components/assets/networks/lifetime.png";
import nbc from "../components/assets/networks/nbc.png";
import netflix from "../components/assets/networks/netflix.png";
import nick from "../components/assets/networks/nick.png";
import xd from "../components/assets/networks/xd.png";

const Networks = [
  {
  name: abc
},
{
  name: cbs
},
{
  name: disney
},
{
  name: fox
},
{
  name: hbo
},
{
  name: hulu
},
{
  name: lifetime
},
{
  name: nbc
},
{
  name: netflix
},
{
  name: nick
},
{
  name: xd
},
]

export default Networks;