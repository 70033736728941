import React from "react";
import styled, { css } from "styled-components";
import { useLocation, Link } from "react-router-dom";

import Pages from "./pages";
import WpaLogo from "./assets/logo-wpa";
import theme from "../theme/theme";
import SocialMedia from "./social-media";

const Footer = styled.div`
  ${theme.FontFamily};
  color: ${theme.PrimaryTextColor};
  font-size: 14px;
  text-align: center;
  width: 100%;
  //max-width: 850px;
  margin: 0 auto;
  padding-bottom: 10px;

   ${props => !props.$isHomePage && css`
    background-color: ${theme.PrimaryColorOnDark};
    border-top: 1px solid #CCCCCC;
  `}

  ${props => props.$isHomePage && css`
    background-color: transparent;
    position: fixed;
    bottom: 0;
    left: 0;
  `}
`;

const FooterLogo = styled(WpaLogo)`
  color: #000000;
  width: 150px;
  height: auto;
  padding: 10px 10px;
`;

const Text = styled.div`
  ${theme.FontFamily}
  color: ${props => props.$isHomePage ? theme.PrimaryColorOnDark : theme.PrimaryTextColor};
  text-align: center;

`;

const LinkStyled = styled(Link)`
  ${theme.AppearanceNone}
  ${theme.FontFamily}

  font-size: 14px;
  margin: 6px 0 4px;
  text-transform: uppercase;

  &:hover {
    ${theme.FontFamilyMedium};
    color: ${theme.PrimaryTextColor};
  }
`;


const FooterPage = () => {

  const location = useLocation();

  const isHomePage = location.pathname === "/";
  console.log('home', isHomePage)

  const isLandingPage = location.pathname === "/become-a-star";
  console.log('landingPage', isLandingPage)

    if (location.pathname === "/become-a-star") {
      return <div />
  };

  return (
    <Footer $isHomePage={isHomePage}>
      <Pages.Lane>
        {!isHomePage && (
          <>
            <FooterLogo />
            <SocialMedia />
          </>
        )}
      </Pages.Lane>


        <Text $isHomePage={isHomePage}>
          <LinkStyled to="/login">Student Login</LinkStyled>
          <div>
            &copy; {(new Date().getFullYear())} &middot; World Premiere Artists &middot; All Rights Reserved
          </div>
        </Text>
    </Footer>
  )
};

export default FooterPage;