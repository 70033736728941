import { css } from "styled-components";
const theme = {

  PrimaryBgColor: "#FBFBFB",
  PrimaryTextColor: "#222222",
  HighlightColor: "#DB0D0D",
  PrimaryColorOnDark: "#FFFFFF",




  FontFamily() {
    return css`
      font-family: "Lato", sans-serif;
      font-weight: 300;
    `
  },

  FontFamilyMedium() {
    return css`
      font-family: "Lato", sans-serif;
      font-weight: 400;
    `
  },

  FontFamilyBold() {
    return css`
      font-family: "Lato", sans-serif;
      font-weight: 700;
    `
  },

  AppearanceNone() {
    return css`
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      border: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-decoration: none;

      &:focus {
        outline: none;
      }
    `
  },

InputStyles() {
  return css`
    ${theme.AppearanceNone}
    background-color: ${theme.PrimaryBgColor};
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0 0 5px -5px #000;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
    height: 40px;
  `;
},

Button() {
  return css`
  ${theme.AppearanceNone()};
  ${theme.FontFamilyBold()};

  width: 150px;
  height: 50px;

  border: 1px solid transparent;
  box-sizing: border-box;
  margin-top: 30px;
  padding: 5px;

  font-size: 1rem;
  text-align: center;

  transition:
    border-color 250ms ease-in-out, background-color 250ms ease-in-out,
    color 250ms ease-in-out;

  color: ${theme.HighlightColor};
  background-color: ${theme.PrimaryBgColor};

  &:hover {
    color: ${theme.PrimaryBgColor};
    background-color: ${theme.HighlightColor};
    border-color: ${theme.PrimaryBgColor};
  }

  ${props => props.$isContactPage && css`
    background-color: ${theme.HighlightColor};
    color: ${theme.PrimaryBgColor};

    &:hover {
    color: ${theme.HighlightColor};
    background-color: ${theme.PrimaryBgColor};
    border-color: ${theme.HighlightColor};
  }
  `}
`;
}

}

export default theme;