import AaronPaul from "../components/assets/imta-alum/AaronPaul.jpg";
import AshtonKutcher from "../components/assets/imta-alum/AshtonKutcher.jpg";
import BrendaSong from "../components/assets/imta-alum/BrendaSong.jpg";
import EliasHarger from "../components/assets/imta-alum/EliasHarger.jpg";
import ElijahWood from "../components/assets/imta-alum/ElijahWood.jpg";
import EvaLongoria from "../components/assets/imta-alum/EvaLongoria.jpg";
import JessicaBiel from "../components/assets/imta-alum/JessicaBiel.jpg";
import JoshHuhamal from "../components/assets/imta-alum/JoshDuhamal.jpg";
import LoganBrowning from "../components/assets/imta-alum/LoganBrowning.jpg";
import MadisionIseman from "../components/assets/imta-alum/MadisonIseman.jpg";
import RainiRodriguez from "../components/assets/imta-alum/RainiRodriguez.jpg";
import SeanFaris from "../components/assets/imta-alum/SeanFaris.jpg";
import SeanWilliamScott from "../components/assets/imta-alum/SeanWilliamScott.jpg";
import SpencerTomich from "../components/assets/imta-alum/SpencerTomich.jpg";

const Alum = [
    {
      name: "Aaron Paul",
      photo: AaronPaul
    },
    {
  name: "Ashton Kutcher",
  photo: AshtonKutcher
},
 {
      name: "Brenda Song",
      photo: BrendaSong
    },
    {
  name: "Elias Harger",
  photo: EliasHarger
},
{
     name: "Elijah Wood",
     photo: ElijahWood
   },
 {
      name: "Eva Longoria",
      photo: EvaLongoria
    },
    {
  name: "Jessica Biel",
  photo: JessicaBiel
},
    {
  name: "Josh Duhamal",
  photo: JoshHuhamal
},
 {
      name: "Logan Browning",
      photo: LoganBrowning
    },
    {
  name: "Madision Iseman",
  photo: MadisionIseman
},
 {
      name: "Raini Rodriguez",
      photo: RainiRodriguez
    },
    {
  name: "Sean Faris",
  photo: SeanFaris
},
 {
      name: "Sean William Scott",
      photo: SeanWilliamScott
    },
    {
  name: "Spencer Tomich",
  photo: SpencerTomich
},

]

export default Alum;