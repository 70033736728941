import React, { useState} from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from 'styled-components';
import { useLocation } from "react-router-dom";

import theme from "../theme/theme";

import Hamburger from "../components/assets/hamburger";
import CloseHamburger from "../components/assets/close-hamburger";


const MenuButton = styled.button`
  ${theme.AppearanceNone()}

  position: fixed;
  top: 0;
  right: 10px;

  z-index: 1;
`;

const CloseButton = styled.button`
  ${theme.AppearanceNone()}
  color: ${theme.PrimaryColorOnDark};

  position: absolute;
  top: 0;
  right: 10px;
`;

const HamburgerStyled = styled(Hamburger)`
  color: ${theme.PrimaryTextColor};
  padding: 10px;

  width: 30px;
  height: auto;

  ${props => props.$isLandingPage && css`
    color: ${theme.PrimaryBgColor};
  `}
`;

const CloseHamburgerStyled = styled(CloseHamburger)`
  padding: 10px;

  width: 30px;
  height: auto;

`;

const Menu = styled.div`
  background-color: ${theme.PrimaryTextColor};
  color: ${theme.PrimaryBgColor};

  padding-top: 50px;
  padding-right: 50px;

  position:  fixed;
  top: 0;
  right: 0;
  bottom: 0;

  transform: translateX(${props => props.$isOpen ? 0 : 100}%);

  transition: transform 250ms ease-in-out;
  z-index: 1;
`;


const LinkStyled = styled(NavLink)`
  ${theme.AppearanceNone}
  ${theme.FontFamilyBold}

  display: block;
  line-height: 40px;
  padding: 0 30px;
  text-transform: uppercase;

  ${props => props.$active && css`
    color: ${theme.HighlightColor};
  `}
`;

const LinkItem = ({ link }) => {
  const [active, setIsActive] = useState(false);

  return (
    <LinkStyled
      to={link.href}
      $active={active}
      isActive={(match) =>
        match && match.isExact ?
          setIsActive(true) :
          setIsActive(false)
      }>
      {link.title}
    </LinkStyled>
  );
};

const HamburgerMenu = ({pageLinks}) => {

  const location = useLocation();
  const isLandingPage = location.pathname === "/become-a-star";

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MenuButton
        onClick={() => setIsOpen(true)} >
        <HamburgerStyled $isLandingPage={isLandingPage}/>
      </MenuButton>

      <Menu $isOpen={isOpen} onClick={() => setIsOpen(false)}>

        <CloseButton onClick={() => setIsOpen(false)}>
          <CloseHamburgerStyled />
        </CloseButton>

        <nav>
          {pageLinks.map((link, index) => (
            <LinkItem link={link} key={index} />
          ))}
        </nav>
      </Menu>
    </>
  );
}


export default HamburgerMenu;