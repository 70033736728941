import React from 'react';
import styled from 'styled-components';

import NavBar from "./navbar";
import HamburgerMenu from "./hamburger-menu";

const pageLinks = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "About",
    href: "/about",
  },
  {
    title: "Instructors",
    href: "/instructors",
  },
  {
    title: "IMTA",
    href: "/imta",
  },
  {
    title: "FAQ",
    href: "/faq",
  },
  {
    title: "Contact",
    href: "/contact",
  },
]

const NAV_SWITCH_WIDTH = 775;
const DesktopNav = styled.div`
  @media (max-width: ${NAV_SWITCH_WIDTH}px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  @media (min-width: ${NAV_SWITCH_WIDTH + 1}px) {
    display: none;
  }
`;

const NavigationRenderer = () => {
  return (
    <>
      <DesktopNav>
        <NavBar pageLinks={pageLinks}/>
      </DesktopNav>
      <MobileNav>
        <HamburgerMenu pageLinks={pageLinks}/>
      </MobileNav>
    </>
  )
};

export default NavigationRenderer;