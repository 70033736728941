import children from "../components/assets/categories/children.jpg";
import tweens from "../components/assets/categories/tweens.jpg";
import teens from "../components/assets/categories/teens.jpg";
import adult from "../components/assets/categories/adult.jpg";

import commerical from "../components/assets/categories/login-commerical.jpg";
import tv from "../components/assets/categories/login-tv.jpg";
import monologue from "../components/assets/categories/login-monologue.jpg";


const clientPdfs = {
  children: {
    name: "Children",
    image: children,
    children: {
      commericals: {
        name: "Commercials",
        genre: commerical,
        children: [
          {
            name: "Aussie Kids 3-N-1",
            href: "/assets/docs/children/commercials/aussie.pdf"
          },
          {
            name: "Build-A-Bear",
            href: "/assets/docs/children/commercials/build-a-bear.pdf"
          },
          {
            name: "Burt's Bees",
            href: "/assets/docs/children/commercials/burtsbees.pdf"
          },
          {
            name: "Cheerios",
            href: "/assets/docs/children/commercials/cheerios.pdf"
          },
          {
            name: "SoCozy Shampoo",
            href: "/assets/docs/children/commercials/cozy-shampoo.pdf"
          },
          {
            name: "SoCozy Waxstick",
            href: "/assets/docs/children/commercials/cozy-waxstick.pdf"
          },
          {
            name: "Crest",
            href: "/assets/docs/children/commercials/crest.pdf"
          },
          {
            name: "Jelly Belly Jelly Beans",
            href: "/assets/docs/children/commercials/jellybeans.pdf"
          },
          {
            name: "McDonald's",
            href: "/assets/docs/children/commercials/mcdonalds.pdf"
          },
          {
            name: "Pepperidge Farm Goldfish",
            href: "/assets/docs/children/commercials/pepperidge.pdf"
          },
          {
            name: "Pop-Tarts",
            href: "/assets/docs/children/commercials/poptarts.pdf"
          },
          {
            name: "Sid The Science Kid",
            href: "/assets/docs/children/commercials/sciencekid.pdf"
          },
          {
            name: "Scooby Doo",
            href: "/assets/docs/children/commercials/scoobydoo.pdf"
          },
          {
            name: "Skechers Twinkle Toes",
            href: "/assets/docs/children/commercials/skechers.pdf"
          },
          {
            name: "Skippy",
            href: "/assets/docs/children/commercials/skippy.pdf"
          },
          {
            name: "Spiderman Body Wash",
            href: "/assets/docs/children/commercials/spiderman.pdf"
          },
          {
            name: "Strawberries By Emily Medrano",
            href: "/assets/docs/children/commercials/strawberries.pdf"
          }
        ]
      },
      tv: {
        name: "TV Scenes",
        genre: tv,
        children: [
          {
            name: "Female - Ashley",
            href: "/assets/docs/children/tv/female-ashley.pdf"
          },
          {
            name: "Female - Kaitlin",
            href: "/assets/docs/children/tv/female-kaitlin.pdf"
          },
          {
            name: "Male - Bobby",
            href: "/assets/docs/children/tv/male-bobby.pdf"
          },
          {
            name: "Male - Jacob",
            href: "/assets/docs/children/tv/male-jacob.pdf"
          }
        ]
      },
      monologues: {
        name: "Monologues",
        genre: monologue,
        children: [
          {
            name: "Aussie Kids 3-N-1",
            href: "/assets/docs/children/monologues/abe.pdf"
          },
          {
            name: "Addy",
            href: "/assets/docs/children/monologues/addy.pdf"
          },
          {
            name: "Aladdin",
            href: "/assets/docs/children/monologues/aladdin.pdf"
          },
          {
            name: "Bean",
            href: "/assets/docs/children/monologues/bean.pdf"
          },
          {
            name: "Disney Danger",
            href: "/assets/docs/children/monologues/disney-danger.pdf"
          },
          {
            name: "Louis",
            href: "/assets/docs/children/monologues/louis.pdf"
          },
          {
            name: "Mandy",
            href: "/assets/docs/children/monologues/mandy.pdf"
          },
          {
            name: "Princess",
            href: "/assets/docs/children/monologues/princess.pdf"
          },
          {
            name: "Shonda",
            href: "/assets/docs/children/monologues/shonda.pdf"
          },
          {
            name: "Sleep Talking",
            href: "/assets/docs/children/monologues/sleeptalking.pdf"
          },
          {
            name: "Slugworth",
            href: "/assets/docs/children/monologues/slugworth.pdf"
          },
          {
            name: "Snoring",
            href: "/assets/docs/children/monologues/snoring.pdf"
          },
          {
            name: "Snow White",
            href: "/assets/docs/children/monologues/snow-white.pdf"
          },
          {
            name: "The Tooth Fairy",
            href: "/assets/docs/children/monologues/toothfairy.pdf"
          },
          {
            name: "TV Show",
            href: "/assets/docs/children/monologues/tv-show.pdf"
          },
          {
            name: "Great Uncle Al",
            href: "/assets/docs/children/monologues/uncle-al.pdf"
          },
          {
            name: "Valentine's Day Rules",
            href: "/assets/docs/children/monologues/valentines.pdf"
          },
          {
            name: "William",
            href: "/assets/docs/children/monologues/william.pdf"
          }
        ]
      }
    }
  },
  tweens: {
    name: "Tweens",
    image: tweens,
    children: {
      commericals: {
        name: "Commercials",
        genre: commerical,
        children: [
          {
            name: "Axe",
            href: "/assets/docs/tweens/commercials/axe.pdf"
          },
          {
            name: "Burger King",
            href: "/assets/docs/tweens/commercials/burgerking.pdf"
          },
          {
            name: "Cap'n Crunch",
            href: "/assets/docs/tweens/commercials/cap-n-crunch.pdf"
          },
          {
            name: "Coppertone Kids Sunscreen Stick",
            href: "/assets/docs/tweens/commercials/copperstone.pdf"
          },
          {
            name: "Crest + Scope",
            href: "/assets/docs/tweens/commercials/crest.pdf"
          },
          {
            name: "Funky Spiker Gel",
            href: "/assets/docs/tweens/commercials/gel.pdf"
          },
          {
            name: "Glam Glitter Gum",
            href: "/assets/docs/tweens/commercials/glitter.pdf"
          },
          {
            name: "Kashi Granola Bars",
            href: "/assets/docs/tweens/commercials/granola.pdf"
          },
          {
            name: "McDonald's",
            href: "/assets/docs/tweens/commercials/kiss.pdf"
          },
          {
            name: "Kiss My Face Lip Balm",
            href: "/assets/docs/tweens/commercials/pepperidge.pdf"
          },
          {
            name: "Knotty Girl Hair Care",
            href: "/assets/docs/tweens/commercials/knottygirl.pdf"
          },
          {
            name: "Lunchables Uploaded",
            href: "/assets/docs/tweens/commercials/lunchables.pdf"
          },
          {
            name: "Made For Boys Total Body Wash",
            href: "/assets/docs/tweens/commercials/madeforboys.pdf"
          },
          {
            name: "M & M Candy",
            href: "/assets/docs/tweens/commercials/mms.pdf"
          },
          {
            name: "Nerf Bash Ball",
            href: "/assets/docs/tweens/commercials/nerf-basball.pdf"
          },
          {
            name: "Nerf Shotwave Blaster",
            href: "/assets/docs/tweens/commercials/nerf-blaster.pdf"
          },
          {
            name: "Oreos",
            href: "/assets/docs/tweens/commercials/oreos.pdf"
          },
          {
            name: "Pringles",
            href: "/assets/docs/tweens/commercials/pringles.pdf"
          },
          {
            name: "Snickers",
            href: "/assets/docs/tweens/commercials/snickers.pdf"
          },
          {
            name: "Starburst",
            href: "/assets/docs/tweens/commercials/starburst.pdf"
          },
          {
            name: "Sunny D",
            href: "/assets/docs/tweens/commercials/sunnyd.pdf"
          },
          {
            name: "Tootsie Roll",
            href: "/assets/docs/tweens/commercials/tootsie-roll.pdf"
          },
          {
            name: "Whoppers",
            href: "/assets/docs/tweens/commercials/whoppers.pdf"
          },
        ]
      },
      tv: {
        name: "TV Scenes",
        genre: tv,
        children: [
          {
            name: "Female/Male - Abby & Dylan",
            href: "/assets/docs/tweens/tv/abby-dylan.pdf"
          },
          {
            name: "Female/Male - Kevin & Reagan",
            href: "/assets/docs/tweens/tv/kevin-reagan.pdf"
          },
          {
            name: "Male - Mason",
            href: "/assets/docs/tweens/tv/mason.pdf"
          }
        ]
      },
      monologues: {
        name: "Monologues",
        genre: monologue,
        children: [
          {
            name: "Abe",
            href: "/assets/docs/tweens/monologues/abe.pdf"
          },
          {
            name: "Addy",
            href: "/assets/docs/tweens/monologues/addy.pdf"
          },
          {
            name: "Bean",
            href: "/assets/docs/tweens/monologues/bean.pdf"
          },
          {
            name: "Katy",
            href: "/assets/docs/tweens/monologues/katy.pdf"
          },
          {
            name: "Lee",
            href: "/assets/docs/tweens/monologues/lee.pdf"
          },
          {
            name: "Louis",
            href: "/assets/docs/tweens/monologues/louis.pdf"
          },
          {
            name: "Mandy",
            href: "/assets/docs/tweens/monologues/mandy.pdf"
          },
          {
            name: "Marissa",
            href: "/assets/docs/tweens/monologues/marissa.pdf"
          },
          {
            name: "My Father's Wife",
            href: "/assets/docs/tweens/monologues/myfather.pdf"
          },
          {
            name: "Neverland 911",
            href: "/assets/docs/tweens/monologues/neverland911.pdf"
          },
          {
            name: "Nina",
            href: "/assets/docs/tweens/monologues/nina.pdf"
          },
          {
            name: "Shonda",
            href: "/assets/docs/tweens/monologues/shonda.pdf"
          },
          {
            name: "Slugworth",
            href: "/assets/docs/tweens/monologues/slugworth.pdf"
          },
          {
            name: "Star to Be",
            href: "/assets/docs/tweens/monologues/star.pdf"
          },
          {
            name: "The Horrors of Holidays",
            href: "/assets/docs/tweens/monologues/thehorrors.pdf"
          },
          {
            name: "The Last Meal",
            href: "/assets/docs/tweens/monologues/thelastmeal.pdf"
          },
          {
            name: "Toon Time",
            href: "/assets/docs/tweens/monologues/toontime.pdf"
          },
          {
            name: "Will",
            href: "/assets/docs/tweens/monologues/will.pdf"
          },
          {
            name: "William",
            href: "/assets/docs/tweens/monologues/william.pdf"
          }
        ]
      }
  },
  },
  teens: {
    name: "Teens",
    image: teens,
    children: {
      commericals: {
        name: "Commercials",
        genre: commerical,
        children: [
          {
            name: "Acne Free",
            href: "/assets/docs/teens/commercials/acne-free.pdf"
          },
          {
            name: "Apple Jacks",
            href: "/assets/docs/teens/commercials/apple-jacks.pdf"
          },
          {
            name: "Aussie",
            href: "/assets/docs/teens/commercials/aussie.pdf"
          },
          {
            name: "Aussie Men 2N1 Shampoo",
            href: "/assets/docs/teens/commercials/aussie-men.pdf"
          },
          {
            name: "Axe",
            href: "/assets/docs/teens/commercials/axe.pdf"
          },
          {
            name: "Ben and Jerry's Ice Cream",
            href: "/assets/docs/teens/commercials/ben-and-jerrys.pdf"
          },
          {
            name: "Bioré Acne Clearing Scrub",
            href: "/assets/docs/teens/commercials/biore.pdf"
          },
          {
            name: "Burger King",
            href: "/assets/docs/teens/commercials/burgerking.pdf"
          },
          {
            name: "Burt’s Bees Men’s Deodorant",
            href: "/assets/docs/teens/commercials/burtsbees-men.pdf"
          },
          {
            name: "Butterfinger Minis",
            href: "/assets/docs/teens/commercials/butterfinger.pdf"
          },
          {
            name: "Cap’n Crunch Treat Bars",
            href: "/assets/docs/teens/commercials/cap-n-crunch.pdf"
          },
          {
            name: "Care Bear Gummy Bear’s",
            href: "/assets/docs/teens/commercials/care-bear.pdf"
          },
          {
            name: "Clearasil Cleanser",
            href: "/assets/docs/teens/commercials/clearasil.pdf"
          },
          {
            name: "Comedy Central",
            href: "/assets/docs/teens/commercials/comedy-central.pdf"
          },
          {
            name: "Converse",
            href: "/assets/docs/teens/commercials/converse.pdf"
          },
          {
            name: "Cosmo Girl",
            href: "/assets/docs/teens/commercials/cosmo-girl.pdf"
          },
          {
            name: "Crest 3D White",
            href: "/assets/docs/teens/commercials/crest-3d.pdf"
          },
          {
            name: "Disneyland",
            href: "/assets/docs/teens/commercials/disneyland.pdf"
          },
          {
            name: "Garnier",
            href: "/assets/docs/teens/commercials/garnier.pdf"
          },
          {
            name: "Glam Vainiall Cream",
            href: "/assets/docs/teens/commercials/glam-vanilla.pdf"
          },
          {
            name: "Hello Breath Spray",
            href: "/assets/docs/teens/commercials/hello.pdf"
          },
          {
            name: "Honey Main Graham Crackers",
            href: "/assets/docs/teens/commercials/honey-maid.pdf"
          },
          {
            name: "Ice Breaker Mints",
            href: "/assets/docs/teens/commercials/ice-breaker.pdf"
          },
          {
            name: "Johnson's Baby Oil",
            href: "/assets/docs/teens/commercials/johnsons.pdf"
          },
          {
            name: "Kettle Toasted Corn Tias",
            href: "/assets/docs/teens/commercials/kettle.pdf"
          },
          {
            name: "Laughing Cow Cream Cheese",
            href: "/assets/docs/teens/commercials/laughing-cow.pdf"
          },
          {
            name: "Life Savers Gummies",
            href: "/assets/docs/teens/commercials/life-savers.pdf"
          },
          {
            name: "Lip-Balm Shine",
            href: "/assets/docs/teens/commercials/lip-balm.pdf"
          },
          {
            name: "M&M Candy",
            href: "/assets/docs/teens/commercials/mms.pdf"
          },
          {
            name: "McDonald's",
            href: "/assets/docs/teens/commercials/mcdonalds.pdf"
          },
          {
            name: "Neutrogena Pore Refining Cleanser",
            href: "/assets/docs/teens/commercials/neutrogena.pdf"
          },
          {
            name: "Reese’s Pieces",
            href: "/assets/docs/teens/commercials/reeses.pdf"
          },
          {
            name: "Revlon Colorstay Lipstick",
            href: "/assets/docs/teens/commercials/revlon-lip.pdf"
          },
          {
            name: "Revlon Nail Art Expressionist",
            href: "/assets/docs/teens/commercials/revlon-expression.pdf"
          },
          {
            name: "Revlon Nail Art Moon Candy",
            href: "/assets/docs/teens/commercials/revlon-mooon.pdf"
          },
          {
            name: "Snicker",
            href: "/assets/docs/teens/commercials/snicker.pdf"
          },
          {
            name: "Sprint",
            href: "/assets/docs/teens/commercials/sprint.pdf"
          },
          {
            name: "Starburst",
            href: "/assets/docs/teens/commercials/starburst.pdf"
          },
          {
            name: "Sunny D",
            href: "/assets/docs/teens/commercials/sunnyd.pdf"
          },
          {
            name: "Tootsie Roll Minis",
            href: "/assets/docs/teens/commercials/tootsieroll.pdf"
          },
          {
            name: "Whoppers",
            href: "/assets/docs/teens/commercials/whoppers.pdf"
          },
          {
            name: "Wonka Rainbow Nerds",
            href: "/assets/docs/teens/commercials/wonka-nerds.pdf"
          },
          {
            name: "Wonka Rainbow Nerd’s Rope",
            href: "/assets/docs/teens/commercials/wonka-rope.pdf"
          },
          {
            name: "Yofi Cheer Makeup",
            href: "/assets/docs/teens/commercials/yofi.pdf"
          },
        ]
      },
      tv: {
        name: "TV Scenes",
        genre: tv,
        children: [
          {
            name: "Male - Cody",
            href: "/assets/docs/teens/tv/cody.pdf"
          },
          {
            name: "Male - Ethan",
            href: "/assets/docs/teens/tv/ethan.pdf"
          },
          {
            name: "Female - Lexie & Taylor",
            href: "/assets/docs/teens/tv/lexie-taylor.pdf"
          }
        ]
      },
      monologues: {
        name: "Monologues",
        genre: monologue,
        children: [
          {
            name: "Cheer Lead",
            href: "/assets/docs/teens/monologues/cheerlead.pdf"
          },
          {
            name: "Dirt- Not The Good Kind",
            href: "/assets/docs/teens/monologues/dirt.pdf"
          },
          {
            name: "Don't Even",
            href: "/assets/docs/teens/monologues/donteven.pdf"
          },
          {
            name: "Dream On",
            href: "/assets/docs/teens/monologues/dreamon.pdf"
          },
          {
            name: "Family Affair",
            href: "/assets/docs/teens/monologues/family-affair.pdf"
          },
          {
            name: "Family Vacation To Hell",
            href: "/assets/docs/teens/monologues/family-vacation.pdf"
          },
          {
            name: "Forever Me",
            href: "/assets/docs/teens/monologues/forever.pdf"
          },
          {
            name: "Heaven",
            href: "/assets/docs/teens/monologues/heaven.pdf"
          },
          {
            name: "Huck",
            href: "/assets/docs/teens/monologues/huck.pdf"
          },
          {
            name: "Katy",
            href: "/assets/docs/teens/monologues/katy.pdf"
          },
          {
            name: "Marissa",
            href: "/assets/docs/teens/monologues/marissa.pdf"
          },
          {
            name: "Neverland 911",
            href: "/assets/docs/teens/monologues/neverland.pdf"
          },
          {
            name: "Nickname Calling",
            href: "/assets/docs/teens/monologues/nickname.pdf"
          },
          {
            name: "Powerful",
            href: "/assets/docs/teens/monologues/powerful.pdf"
          },
          {
            name: "Rebel",
            href: "/assets/docs/teens/monologues/rebel.pdf"
          },
          {
            name: "Sins of a Father",
            href: "/assets/docs/teens/monologues/sins.pdf"
          },
          {
            name: "Something Worth Fighting For",
            href: "/assets/docs/teens/monologues/something.pdf"
          },
          {
            name: "Star to Be",
            href: "/assets/docs/teens/monologues/star.pdf"
          }
        ]
      }
    }
  },
  adults: {
    name: "Adults",
    image: adult,
    children: {
      commericals: {
        name: "Commercials",
        genre: commerical,
        children: [
          {
            name: "Apple Jacks",
            href: "/assets/docs/adults/commercials/apple-jacks.pdf"
          },
          {
            name: "Aussie Men Hair Care",
            href: "/assets/docs/adults/commercials/aussie-men.pdf"
          },
          {
            name: "Aveeno 2 in 1 Shampoo",
            href: "/assets/docs/adults/commercials/aveeno-2n1.pdf"
          },
          {
            name: "Aveeno After Shave Lotion",
            href: "/assets/docs/adults/commercials/aveeno-aftershave.pdf"
          },
          {
            name: "Axe Massage Shower Gel",
            href: "/assets/docs/adults/commercials/axe.pdf"
          },
          {
            name: "Burt’s Bees Lip Balm",
            href: "/assets/docs/adults/commercials/burtsbees.pdf"
          },
          {
            name: "Burt’s Skin Care For Men",
            href: "/assets/docs/adults/commercials/burtsmen.pdf"
          },
          {
            name: "Claussen Pickles",
            href: "/assets/docs/adults/commercials/claussen.pdf"
          },
          {
            name: "Clinique Dark Circle Corrector",
            href: "/assets/docs/adults/commercials/clinique.pdf"
          },
          {
            name: "Comedy Central",
            href: "/assets/docs/adults/commercials/comedy-central.pdf"
          },
          {
            name: "Geico",
            href: "/assets/docs/adults/commercials/geico.pdf"
          },
          {
            name: "Kaiser Permanente",
            href: "/assets/docs/adults/commercials/kaiser.pdf"
          },
          {
            name: "Kettle Jalapeno Potato Chips",
            href: "/assets/docs/adults/commercials/kettle-jalapeno.pdf"
          },
          {
            name: "Kettle Maple Bacon Chips",
            href: "/assets/docs/adults/commercials/kettle-maple.pdf"
          },
          {
            name: "Kettle Spicy Thai Potato Chips",
            href: "/assets/docs/adults/commercials/kettle-spicy.pdf"
          },
          {
            name: "LANCÔME Trésor Midnight Rose",
            href: "/assets/docs/adults/commercials/lancome.pdf"
          },
          {
            name: "Nasacort",
            href: "/assets/docs/adults/commercials/nasacort.pdf"
          },
          {
            name: "Origins Plantscription",
            href: "/assets/docs/adults/commercials/origins.pdf"
          },
          {
            name: "Pepsi",
            href: "/assets/docs/adults/commercials/pepsi.pdf"
          },
          {
            name: "Planters Peanut Butter",
            href: "/assets/docs/adults/commercials/planters.pdf"
          },
          {
            name: "Prilosec OTC",
            href: "/assets/docs/adults/commercials/prilosec.pdf"
          },
          {
            name: "Progressive Insurance",
            href: "/assets/docs/adults/commercials/progressive.pdf"
          },
          {
            name: "Rescue Remedy",
            href: "/assets/docs/adults/commercials/rescue.pdf"
          },
          {
            name: "Skinny Cow Ice Cream",
            href: "/assets/docs/adults/commercials/skinny-cow.pdf"
          },
          {
            name: "Special K Fudge Dipped Pretzels",
            href: "/assets/docs/adults/commercials/specialk-fudge.pdf"
          },
          {
            name: "Special K Moments",
            href: "/assets/docs/adults/commercials/specialk-moments.pdf"
          },
          {
            name: "Sprint",
            href: "/assets/docs/adults/commercials/sprint.pdf"
          },
          {
            name: "Starbucks",
            href: "/assets/docs/adults/commercials/starbucks.pdf"
          },
          {
            name: "Trop 50 Pomegranate Blueberry",
            href: "/assets/docs/adults/commercials/trop.pdf"
          },
          {
            name: "Visible Lift Color-Collection Cream",
            href: "/assets/docs/adults/commercials/visible.pdf"
          }
        ]
      },
      tv: {
        name: "TV Scenes",
        genre: tv,
        children: [
          {
            name: "Female - Angela & Marie",
            href: "/assets/docs/adults/tv/angela-marie.pdf"
          },
          {
            name: "Female - Kate & Jen",
            href: "/assets/docs/adults/tv/kate-jen.pdf"
          },
          {
            name: "Female - Trisha & Kayleigh",
            href: "/assets/docs/adults/tv/trisha-kayleigh.pdf"
          },
          {
            name: "Female/Male - Dalton & Paige",
            href: "/assets/docs/adults/tv/dalton-paige.pdf"
          },
          {
            name: "Female/Male - Jared & Amanda",
            href: "/assets/docs/adults/tv/jared-amanda.pdf"
          },
          {
            name: "Female/Male - Reilly & Erin",
            href: "/assets/docs/adults/tv/reilly-erin.pdf"
          },
        ]
      },
      monologues: {
        name: "Monologues",
        genre: monologue,
        children: [
          {
            name: "Boredom",
            href: "/assets/docs/adults/monologues/boredom.pdf"
          },
          {
            name: "Dead Man's Curve",
            href: "/assets/docs/adults/monologues/dead.pdf"
          },
          {
            name: "Family Affair",
            href: "/assets/docs/adults/monologues/family-affair.pdf"
          },
          {
            name: "Neverland 911",
            href: "/assets/docs/adults/monologues/neverland.pdf"
          },
          {
            name: "Sins of a Father",
            href: "/assets/docs/adults/monologues/sins.pdf"
          },
          {
            name: "Something Worth Fighting For",
            href: "/assets/docs/adults/monologues/something.pdf"
          },
          {
            name: "The Disappointment",
            href: "/assets/docs/adults/monologues/disappointment.pdf"
          },
          {
            name: "The Transformation",
            href: "/assets/docs/adults/monologues/transformation.pdf"
          },
          {
            name: "Unfaithful",
            href: "/assets/docs/adults/monologues/unfaithful.pdf"
          },
          {
            name: "Up All Night",
            href: "/assets/docs/adults/monologues/up.pdf"
          },
          {
            name: "Walking the Dog",
            href: "/assets/docs/adults/monologues/walking.pdf"
          },
          {
            name: "Waxed Off",
            href: "/assets/docs/adults/monologues/waxed.pdf"
          },
          {
            name: "Wrong Number",
            href: "/assets/docs/adults/monologues/wrong.pdf"
          }
        ]
      }
    }
  }
};

export default clientPdfs;
