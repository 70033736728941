import React from "react";
import styled, { css, keyframes } from "styled-components";
import theme from "../theme/theme";
import { Link } from "react-router-dom";
import { Spring, animated } from "react-spring";
import LazyLoad from 'react-lazyload';

import SocialMedia from "../components/social-media"

import Photo1 from "../components/assets/background/photo1-small.jpeg";
import Photo2 from "../components/assets/background/photo2-small.jpeg";
// import Photo3 from "../components/assets/background/photo3.jpg";
import Photo4 from "../components/assets/background/photo4-small.jpeg";
import Photo5 from "../components/assets/background/photo5-small.jpeg";
import Photo6 from "../components/assets/background/photo6-small.jpeg";

import WpaLogo from "../components/assets/logo-wpa";

const slide = keyframes`
  0% {
    opacity: 0.9;
    background-image: url(${Photo1});
  }
  15% {
    opacity: 1;
    background-image: url(${Photo1});
  }
  15.1% {
    opacity: 0.9;
    background-image: url(${Photo2});
  }
  30% {
    opacity: 1;
    background-image: url(${Photo2});
  }
  30.1% {
    opacity: 0.9;
    background-image: url(${Photo1});
  }
  40% {
    opacity: 1;
    background-image: url(${Photo1});
  }
  40.1% {
    opacity: 0.9;
    background-image: url(${Photo4});
  }
  60% {
    opacity: 1;
    background-image: url(${Photo4});
  }
  60.1% {
    opacity: 0.9;
    background-image: url(${Photo5});
  }
  80% {
    opacity: 1;
    background-image: url(${Photo5});
  }
  80.1% {
    opacity: 0.9;
    background-image: url(${Photo6});
  }
  100% {
    opacity: 1;
    background-image: url(${Photo6});
  }
`;

const animation = props =>
  css`
    ${slide} ${props.animationLength} 20s ease-in-out 1s infinite alternate;
    position: fixed;
    background-position: center 0;
  `;

const HomePageContainer = styled.div`
  background-color: #222222;
  overflow: hidden;
  position: relative;

  text-align: center;
  width: 100vw;
  height: 100vh;
`;

const Overlay = styled.div`
  background: rgba(0,0,0, 0.5);
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;

const Photos = styled(animated.div)`
  animation: ${animation};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
`;

const HomeNavContainer = styled.div`
  margin: 0 auto;
  position: absolute;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: auto;
`;

const Logo = styled(WpaLogo)`
  color: ${theme.PrimaryBgColor};
  width: 300px;
  height: auto;
  padding-bottom: 5px;
`;

const LinkContainer = styled.div`
  margin 10px auto 20px;
`;


const LinkStyled = styled(Link)`
  ${theme.AppearanceNone}
  ${theme.FontFamilyMedium};
  text-shadow: 0px 0px 5px rgba(0,0,0,0);
  color: ${theme.PrimaryBgColor};
  opacity: 70%;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 15px;

  &:hover {
    ${theme.FontFamilyMedium};
    color: ${theme.HighlightColor};
  }

  @media (max-width: 600px) {
    font-size: 80%;
  }
`;

const HomePage = () => {
  return (
    <HomePageContainer>

      <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ duration: 500, delay: 0}}
          >
          { props =>
            <div>
              <LazyLoad
                height={700}
                src= {{animation}}>
              <Photos $animation
                style={props}>
      <Overlay>
      <HomeNavContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
          <LinkContainer>
            <LinkStyled to="/about">About</LinkStyled>
            <LinkStyled to="/instructors">Instructors</LinkStyled>
            <LinkStyled to="/imta">IMTA</LinkStyled>
            <LinkStyled to="/faq">FAQ</LinkStyled>
            <LinkStyled to="/contact">Contact</LinkStyled>
          </LinkContainer>
        <div>
          <SocialMedia />
        </div>


      </HomeNavContainer >
      </Overlay>
      </Photos>
      </LazyLoad>
      </div>
      }
      </Spring>

            </HomePageContainer>

  );
}

export default HomePage;