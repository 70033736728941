import styled from "styled-components";
import theme from "../theme/theme";

import SocialInsta from "./assets/social-insta";
import SocialFb from "./assets/social-fb";

const IconContainer = styled.div`
  color: ${theme.HighlightColor};
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 0px;

  max-width: 120px;

`;

const links = [
  {
    icon: SocialInsta,
    url: "https://www.instagram.com/worldpremiereartists/",
  },
  {
    icon: SocialFb,
    url: "https://www.facebook.com/worldpremiereartists",
  }
];

const LinkIcon = styled.a`
  padding-left: 5px;
 &: hover {
   color: ${theme.PrimaryBgColor};
 }
`;


const SocialMedia = () => {
  return (
    <div>
  <IconContainer>
    {links.map((l, index) => {
      const Icon = l.icon;

            return (
              <LinkIcon target="_blank" rel="noreferrer" href={l.url} key=
              {index}>
                <Icon />
              </LinkIcon>
            )
          })}
        </IconContainer>
        </div>


)};

export default SocialMedia;