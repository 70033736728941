import React from "react";

const ArrowDown = ({ className }) => (
  <svg width="25px" height="15px" viewBox="0 0 31 20" version="1.1" preserveAspectRatio="xMidYMid meet" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <polygon points="27.344543 0.907 15.5 12.6554923 3.65545703 0.907 0 4.5326938 15.5 19.907 31 4.5326938"></polygon>
      </g>
    </g>
  </svg>

);

export default ArrowDown;