import React from "react";
import { useSpring } from "react-spring";


import Pages from "../components/pages";
import Title from "../components/title";
import Form from "../components/form";

const ContactPage = () => {

  const props = useSpring({
    opacity: 1, from: {opacity: 0},
    delay: 200, duration: 500
  })

  return (
    <Pages style={props}>
      <Pages.Lane>
        <Pages.Section>
        <Title>Contact Us</Title>
          <Form/>
        </Pages.Section>
      </Pages.Lane>
    </Pages>
  );
}

export default ContactPage;