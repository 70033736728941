import { createGlobalStyle } from "styled-components";
import theme from "../theme/theme";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.PrimaryBgColor};
    color: ${theme.PrimaryTextColor};
    font-size: 18px;
  }

  a {
    color: inherit;
    cursor: pointer;
    transition: color 250ms ease-in-out;

    &:hover {
      color: ${theme.HighlightColor};
    }
  }

  button {
    cursor: pointer;
  }

  img {
    vertical-align: top;
  }

  p {
    margin: 0;
  }

  input, textarea {
    ${theme.FontFamilyMedium}
  }
`;

export default GlobalStyle;