import React from "react";

import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import NavigationRenderer from "./components/navigation-renderer";

import Footer from "./components/footer";
import GlobalStyle from "./components/global-style";

import Home from "./pages/home";
import About from "./pages/about";
import Instructors from "./pages/instructors";
import Imta from "./pages/imta";
import Faq from "./pages/faq";
import Contact from "./pages/contact";
import Login from "./pages/login";
import LandingPage from "./pages/become-a-star";
import Students from "./pages/students";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <NavigationRenderer/>

      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/instructors" component={Instructors} />
      <Route exact path="/imta" component={Imta} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/become-a-star" component={LandingPage} />
      <Route exact path="/students" component={Students} />
      <Footer />
    </Router >

  );
}

export default App;
