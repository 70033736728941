import React from "react";
import styled from "styled-components";
import { useSpring } from "react-spring";

import Pages from "../components/pages";
import Title from "../components/title";
import theme from "../theme/theme.js";

import ImtaLogo from "../components/assets/imta-logo";
import ButtonContact from "../components/button-contact";
import ImtaAlum from "../utils/imta-alum";


const SvgContainer = styled(ImtaLogo)`
  width: 200px;
  height: auto;
  padding: 0 10px 5px 0;
  float: left;
`;

const WrapText = styled.div`
  text-align: justify;
`;

const MainContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Instructor = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  text-align: justify;
  justify-content: center;

  width: auto;
  height: auto;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-right: 10px;
`;

const Overlay = styled.div`
  background: rgba(0,0,0, 0.5);
  background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Photo = styled.img`
  object-fit: cover;
  width: 200px;
  height: 300px;
`;

const Name = styled.div`
  ${theme.FontFamilyBold}
  color: ${theme.PrimaryColorOnDark};
  text-align: center;
  text-transform: uppercase;

  position: absolute;
  bottom: 5px;
  width: 100%;
`;

const ImtaPage = () => {

    const props = useSpring({
    opacity: 1, from: {opacity: 0},
    delay: 200, duration: 500
  })

  return (
    <Pages style={props}>
      <Pages.Lane>
        <Pages.Section>
          <Title>IMTA History</Title>

          <Pages.P>
            <SvgContainer><ImtaLogo /></SvgContainer>
            <WrapText>
              IMTA’s first convention was produced in 1987 in New York City. IMTA quickly became known in the modeling and talent industries as the premiere professional event to scout for the best new faces for the entertainment world. The early days of IMTA saw the discovery of rising stars such as actor Elijah Wood and model Joel West. Throughout the 1990s and into the 21st century, IMTA’s success and influence grew steadily, launching the careers of many of today’s hottest models and actors such as Katie Holmes, Ashton Kutcher, Jessica Biel, Seann William Scott, Brandon Routh, Eva Longoria and Alyson Stoner. Models such as Jessica White, Bradley Tomberlin, Sara Dawson, Kim Lemanton, Sean Harju and Tyson Ballou also began their careers on the IMTA runway.
          </WrapText>
          </Pages.P>
          <Pages.P>
            IMTA remains the largest and most successful event of its kind in the world with a proven track record of success. With over three decades of experience, each IMTA event produced is truly a one-of-a-kind “Experience of a Lifetime!” Click our IMTA success button below to see why!
          </Pages.P>
          <br />

          <Title>How it Works</Title>

          <Pages.P>
            The International Modeling and Talent Association (IMTA) is a professional association of the finest and most successful modeling and talent training centers in the world. Since 1987, IMTA has become the recognized leader in putting new faces in front of the people whose business it is to identify the next rising star or supermodel. An impressive, and constantly growing, list of people who were first seen at IMTA conventions are now seen in magazines, TV, movies and fashion shows.
          </Pages.P>
          <Pages.P>
            IMTA produces multiple week-long conventions, including one in New York and Los Angeles, consisting of modeling, acting, singing, songwriting and dancing competitions. Hundreds of fashion and talent agents, personal managers, casting directors, network representatives and music producers judge IMTA competitions while searching for new models, actors, singers and dancers to work in the fashion and entertainment industries.
          </Pages.P>
          <br />

          <Title>IMTA Alumni</Title>
          <MainContainer>
            <CardContainer>
              {ImtaAlum.map((instructor, index) => {
                return (
                  <Instructor key={index}>
                    <ImageContainer>
                      <Overlay />
                      <Photo src={instructor.photo} />
                      <Name>{instructor.name}</Name>
                    </ImageContainer>
                  </Instructor>
                )})
              }
            </CardContainer>

            <a href="/contact">
              <ButtonContact $isContactPage
                onclick="location.href='/contact'"
                type="button">Contact Us!</ButtonContact>
            </a>


          </MainContainer>
        </Pages.Section>
      </Pages.Lane>
    </Pages >
  );
}

export default ImtaPage;