import React, { useState } from 'react';
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import theme from "../theme/theme";

const SubmitButton = styled.button`
  ${theme.Button}
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const Input = styled.input`
  ${theme.InputStyles}
`;

const Textarea = styled.textarea`
  ${theme.InputStyles}
  resize: vertical;
`;

const Success = styled.div`
  ${theme.FontFamilyMedium}
  text-align: center;
  color: ${theme.PrimaryBgColor};
  padding-top: 30px;
`;


const Form = () => {

  const location = useLocation();
  const isContactPage = location.pathname === "/contact";

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [phone, setPhone] = useState("");
  const [parentsPhone, setParentsPhone] = useState("");
  const [success, setSuccess] = useState(false);


  // Old way of writing React (pre-hooks):
  // constructor(props) {
	// super(props);
	// this.state = { feedback: '', name: 'Name', email: 'email@example.com' };
	// this.handleChange = this.handleChange.bind(this);
	// this.onSubmit = this.onSubmit.bind(this);
  // }

  const onFormReset = () => {
    setDescription("");
    setName("");
    setEmail("");
    setAge("");
    setPhone("");
    setParentsPhone("");
  };

  const sendFeedback = (serviceId, templateId, variables) => {
    window.emailjs.send(
      serviceId,
      templateId,
      variables
    ).then(res => {
      console.log('Email successfully sent!');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      onFormReset();
    })
    // Handle errors here however you like, or use a React error boundary
    .catch(err =>
      console.error('Oh well, you failed. Here some thoughts on the error that occured:', err)
    )
  };


  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const handlePhoneNumber = (value) => {
  return (
    value.replace(phoneRegex, '($1) $2-$3')
    )
  }

  const onSubmit = (event) => {
    const serviceId = "service_gsh68u8";
    const templateId = "template_erednaj";

    sendFeedback(serviceId, templateId, {
      to_name: "World Premiere Artists",
      reply_to: email,
      from_name: name,
      message: description,
      age: age,
      phone: handlePhoneNumber(phone),
      parents_phone: handlePhoneNumber(parentsPhone)
    })
  }

	return (

  	<form>
        <Input onChange={e => setName(e.target.value)} value={name} placeholder="Name" required/>
        <Input onChange={e => setEmail(e.target.value)} value={email} placeholder="Email" type="email" required/>
        <Input onChange={e => setAge(e.target.value)} value={age} placeholder="Age" type="number" required/>
        <Input onChange={e => setPhone(e.target.value)} value={handlePhoneNumber(phone)} pattern="[0-9]*" placeholder="Phone Number" type="tel" required/>
        <Input onChange={e => setParentsPhone(e.target.value)} value={handlePhoneNumber(parentsPhone)} pattern="[0-9]*" placeholder="Parent's Phone Number" type="tel"/>
      	<Textarea
        	id="test-mailing"
        	name="test-mailing"
        	onChange={e => setDescription(e.target.value)}
        	placeholder="Tell us something amazing about you!"
        	required
        	value={description}/>
      <ButtonContainer>
      <SubmitButton $isContactPage={isContactPage}
        type="submit"
        value="Submit"
        onClick={onSubmit}>
          Submit!
      </SubmitButton>
      </ButtonContainer>
        {success && <Success>Message Sent!</Success>}
  	</form>
	)
}

export default Form;