import styled from "styled-components";
import theme from "../theme/theme"

const Title = styled.title`
  ${theme.FontFamilyMedium};

  border-bottom: 1px solid #CCCCCC;
  display: block;
  margin: 0 auto;
  padding-bottom: 5px;
  margin-bottom: 30px;

  height: auto;
  text-align: center;
  font-size: 30px;

  min-width: 70%;
  max-width: 60%;
  }
`;


export default Title;