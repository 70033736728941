import React, { useState } from "react";
import styled, { css } from 'styled-components';
import { NavLink, useLocation } from "react-router-dom";

import WpaLogo from "./assets/logo-wpa-wordless";
import Pages from "./pages";
import theme from "../theme/theme";

const Navigation = styled.nav`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 20px;
`;

const LogoStyled = styled(WpaLogo)`
  color: ${theme.HighlightColor};
  padding: 5px;
  width: auto;
  height: 35px;
`;

const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgb(187, 187, 187);
  box-shadow: 0 6px 3px -6px #BBB;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 45px;
  z-index: 2;

  display: flex;
  align-items: center;
`;

const LinkStyled = styled(NavLink)`
  ${theme.FontFamily};

  ${props => props.$active && css`
    color: ${theme.HighlightColor};
  `}
  font-size: 16px;
  padding-left: 40px;
  text-decoration: none;
  text-transform: uppercase;

  transition:
    color 250ms ease-in-out;
`;

const LinkItem = ({ link }) => {
  const [active, setIsActive] = useState(false);

  return (
    <LinkStyled
      to={link.href}
      $active={active}
      isActive={(match) =>
        match && match.isExact ?
          setIsActive(true) :
          setIsActive(false)
      }>
      {link.title}
    </LinkStyled>
  );
};

const NavBar = ({ pageLinks }) => {
  const location = useLocation();

  if (location.pathname === "/") {
    return <div />
  };
  if (location.pathname === "/become-a-star") {
    return <div />
  };

  return (
    <Container>
      <Pages.Lane>
        <Navigation>
            <LogoStyled />
          <nav>
            {pageLinks.map((link, index) => (
              <LinkItem link={link} key={index} />
              ))}
          </nav>

        </Navigation >
      </Pages.Lane>
    </Container>
  )
};

export default NavBar;



