import React from "react";
import styled from "styled-components";

import theme from "../theme/theme";
import Form from "../components/form";

import Networks from "../components/network-logos"
import TvShows from "../components/assets/TvShows.png"

 const Background = styled.div`
    background: rgb(255,255,255);
    background-image: linear-gradient(144deg, #D36161 0%, #C40B0B 82%);
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: -1;
  `;

  const MainText = styled.div`
    color: ${theme.PrimaryBgColor};
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
    width: 100%;
  `;

  const TvShowsContainer = styled.img`
    display: block;
    text-align: center;

    @media (max-width: 857px) {
      width: 100%;
  }
  `;

  const SubText = styled.div`
    ${theme.FontFamilyMedium}
    color: ${theme.PrimaryBgColor};
    font-size: 20px;
    width: 100%;
    padding-bottom: 30px;
    opacity: 0.7;
  `;

  const Flex = styled.div`
    display: flex;
    flex-direction: row;

    & > form {
      flex: 1;
    }

    @media (max-width: 857px) {
      display: flex;
      padding: 0;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
  }
  `;

  const LeftSide = styled.div`
    padding: 0 30px;
    box-sizing: border-box;

    @media (max-width: 857px) {
      padding: 30px;
      width: 100%;
  }
  `;

  const RightSide = styled.div`
    background: ${theme.PrimaryBgColor};
    padding: 30px;
    text-align: center;
    width: 60%;

    @media (max-width: 857px) {
      text-align: center;
      width: 100%;
  }
  `;

const NetworkLogo = styled.img`
    display: block;
    opacity: 0.9;
    margin: 0 auto;
    padding: 10px 5px;
    max-height: 70px;

    @media (max-width: 857px) {
      padding: 10px 0;
  }
  `;

const H1 = styled.h1`
  text-transform: uppercase;
  line-height: 60px;
`;

const P = styled.p`
  text-transform: uppercase;
  line-height: 30px;
`;

const LandingPage = () => {
  return (
    <>

     <Background />
        <Flex>
          <LeftSide>
            <MainText>
              <H1>
              Are you ready for the spotlight?
              </H1>
              <meta name="description" content="Are you ready to become a working model or actor? Then sign up to train with us now! We will help you become the star you were always meant to be! These are some of the TV Shows and major studio networks such as ABC, Netlfix, HBO, and Hulu that are just some of the platforms that our students end up working with!"/>
              <TvShowsContainer src={TvShows} alt="tvshows"/>
            </MainText>
            <SubText>
              <P>Call us at 1-885-522-8464
              </P>
              <P>
              or contact us here
              </P>
            </SubText>
            <Form />
        </LeftSide>
            <RightSide>
              {Networks.map((network,index) => (
                <NetworkLogo src={network.name} alt="abc" key={index} />
              ))}
            </RightSide>
        </Flex>
    </>
  );
}

export default LandingPage;