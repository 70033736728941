import React from "react";

const SocialFb = ({ className }) => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" preserveAspectRatio="xMidYMid meet" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="CurrentColor" fillRule="nonzero">
            <path d="M23.9999999,11.9999999 C23.9999999,5.37187497 18.628125,-5.00000006e-08 11.9999999,-5.00000006e-08 C5.37187497,-5.00000006e-08 -5.00000006e-08,5.37187497 -5.00000006e-08,11.9999999 C-5.00000006e-08,18.628125 5.37187497,23.9999999 11.9999999,23.9999999 C12.0703125,23.9999999 12.140625,23.9999999 12.2109375,23.9953125 L12.2109375,14.6578125 L9.63281249,14.6578125 L9.63281249,11.653125 L12.2109375,11.653125 L12.2109375,9.44062499 C12.2109375,6.87656248 13.7765625,5.47968747 16.0640625,5.47968747 C17.1609375,5.47968747 18.103125,5.55937497 18.375,5.59687497 L18.375,8.27812498 L16.8,8.27812498 C15.5578125,8.27812498 15.3140625,8.86874999 15.3140625,9.73593749 L15.3140625,11.6484375 L18.290625,11.6484375 L17.9015625,14.653125 L15.3140625,14.653125 L15.3140625,23.5359375 C20.3296875,22.096875 23.9999999,17.4796875 23.9999999,11.9999999 Z"></path>
        </g>
    </g>
</svg>

);

export default SocialFb;