import theme from "../theme/theme";
import styled from "styled-components";
import { animated } from "react-spring";

const Pages = styled(animated.div)`
 ${theme.FontFamily()};
  padding: 0;
  text-align: justify;
`;

const Lane = styled.div`
  background-color: ${theme.PrimaryColorOnDark};
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 850px;
  position: relative;
`;

const P = styled.p`
  margin: 0;
  padding: 0;

   &:not(:first-of-type) {
    margin-top: 30px;
  }
`;


const Section = styled.section`
  box-sizing: border-box;
  margin: 0;
  padding: 80px 10px;
  border-left: 1px solid #BBB;
  border-right: 1px solid #BBB;
  box-shadow: 5px 0px 5px -5px rgba(0,0,0,0.45), -5px 0px 5px -5px rgba(0,0,0,0.45);

  &:not(:first-of-type) {
    margin-top: 30px;
  }
`;


Pages.Section = Section;
Pages.P = P;
Pages.Lane = Lane;
export default Pages;