import React, { useState } from 'react';
import styled from "styled-components";
import { useSpring } from "react-spring";

import theme from "../theme/theme";

import Pages from "../components/pages"
import Title from "../components/title";

const Input = styled.input`
  ${theme.InputStyles}
  text-align: center;
  width: 50%;
`;

const SubmitButton = styled.button`
  ${theme.Button}
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const Success = styled.div`
  ${theme.FontFamilyMedium}
  text-align: center;
  color: ${theme.PrimaryTextColor};
  padding-top: 30px;
`;

const PasswordContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const  Login = () => {

  const props = useSpring({
    opacity: 1, from: {opacity: 0},
    delay: 200, duration: 500
  })

  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const onFormReset = () => {
    setPassword("");
  }

  const checkPass = (event) => {
    if (password==="hello") {
      setPassword(true);
      window.location="/students";
      onFormReset();
    }
    else {
      setPassword(false);
      setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
          onFormReset();
}
}

  return (
    <Pages style={props}>
      <Pages.Lane>
        <Pages.Section>
        <Title>Student Login</Title>

        <PasswordContainer>
          <form>
            <Input
              onChange={e => setPassword(e.target.value)}
              value={password}
              placeholder="Password"
              required/>
              <ButtonContainer>
                <SubmitButton $isContactPage
                  type="submit"
                  value="Submit"
                  onClick={checkPass}>
                    Login!
                </SubmitButton>
              </ButtonContainer>
              {success && <Success>Sorry, try again!</Success>}
          </form>
          </PasswordContainer>

      </Pages.Section>
      </Pages.Lane>
    </Pages>
  );
}


export default Login;