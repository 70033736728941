import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";

import theme from "../theme/theme";
import Pages from "../components/pages"
import Title from "../components/title";
import ButtonContact from "../components/button-contact";

import ClientPdfs from "../utils/client-pdfs";


const CARD_SPACING = 5;
const MainContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const CardContainer = styled(animated.div)`
  // Hack to make sure cards align properly with Page.Lane
  margin: ${CARD_SPACING * -1}px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled(animated.div)`
  box-sizing: border-box;
  padding: ${CARD_SPACING}px;
  position: relative;
  width: 25%;


  @media (max-width: 600px) {
    width: 50%;
  }
`;

const Overlay = styled.div`
  background: rgba(0,0,0, 0.5);
  background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CategoryPhoto = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const CategoryContainer = styled.div`
  position: relative;
`

const CategoryName = styled.div`
  ${theme.FontFamilyBold}
  color: ${theme.PrimaryColorOnDark};
  text-align: center;
  text-transform: uppercase;
  padding: 5px 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  box-sizing: border-box;

  width: 100%;

  &:hover {
    color: ${theme.HighlightColor};
    cursor: pointer;
  }

  @media (max-width: 600px) {
    font-size: 50%;
  }
`;

const PdfNameContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const PdfName = styled.div`
  background-color: #F3F3F3;
  box-sizing: border-box;
  padding: 15px 5px 5px 5px;
  display: flex;
  flex-flow: row wrap;
  height: 90px;
  width: 100%;
  border-radius: 2px;
`;

const PdfNameText = styled.text`
  ${theme.FontFamilyBold}
  color: ${theme.PrimaryTextColor};
  text-transform: uppercase;
  text-align: center;
  display: block;

  width: 100%;
  height: 70%;

  @media (max-width: 700px) {
    font-size: 50%;
    overflow: hidden;
    word-wrap: break-word;
  }
`;

const LinkContainer = styled.div`
  text-align: center;

  width: 100%;
  height: 20%;
`;

const LinkStyled = styled(Link)`
  ${theme.AppearanceNone}
  ${theme.FontFamilyMedium}
  color: ${theme.HighlightColor};
  display: inline;
  font-size: 10px;
  text-transform: uppercase;

  &:hover {
      color: #D36161;
    }

    @media (max-width: 600px) {
    font-size: 50%;
  }
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-bottom: 30px;
`;

const SpringContainer = styled(animated.div)`
  margin: 0 auto;
`;



Modal.setAppElement("#root");
const StudentPage = () => {

  const [ageObject, setAgeObject] = useState(true);
  const [genreObject, setGenreObject] = useState(null);
  const [pdfObject, setPdfObject] = useState(null);

  const props = useSpring({
    delay: 0, duration: 500,
    from: {transform: 'translate3d(0,-40px,0)', opacity: 0},
    to: {transform: 'translate3d(0,0px,0)', opacity: 1},
    reset: true
  })

  return (

    <>
    <Pages>
      <Pages.Lane>
          <MainContainer>
        <Pages.Section>
        <Title>Student Resources</Title>
        <ButtonContainer>
        {genreObject &&  (
            <>
              <ButtonContact $isContactPage
                onClick={() => {
                    setAgeObject(true);
                    setGenreObject(false);
                        }}>Back</ButtonContact>

              </>
              )}

              {pdfObject &&  (
                <>
              <ButtonContact $isContactPage
                onClick={() => {
                    setAgeObject(true);
                    setPdfObject(false);
                        }}>Back</ButtonContact>
              </>
              )}

              </ButtonContainer>

            <SpringContainer >
              <div>
              <CardContainer>
                {ageObject && (
                    <>
                  {Object.keys(ClientPdfs).map((ageName, i) => (
                    <Card style={props}
                      onClick={() => {
                        setGenreObject(ClientPdfs[ageName]);
                        setAgeObject(false);
                      }}
                      key={i}>
                    <CategoryContainer>
                      <Overlay />
                      <CategoryPhoto src={ClientPdfs[ageName].image} alt="ages"/>
                      <CategoryName>
                        {ClientPdfs[ageName].name}
                      </CategoryName>
                      </CategoryContainer>
                    </Card>
                  ))}
                  </>
                  )}
              </CardContainer>
                </div>

              <div>
                  <CardContainer>
                    {genreObject &&  (
                      <>
                        {Object.keys(genreObject.children).map((genreName, i) => (
                          <Card style={props}
                              onClick={() => {
                                setPdfObject(genreObject.children[genreName]);
                                setGenreObject(false);
                              }}
                              key={i}>
                            <CategoryContainer>
                              <Overlay />
                              <CategoryPhoto
                                src={ClientPdfs.children.children[genreName].genre}
                                alt="genres"/>
                              <CategoryName>
                                {genreObject.children[genreName].name}
                              </CategoryName>
                            </CategoryContainer>
                          </Card>
                        ))}
                      </>
                    )}
                  </CardContainer>
                  </div>

                <div>
                  <CardContainer>
                    {pdfObject &&  (
                      <>
                        {pdfObject.children.map((pdf, i) => (
                          <Card
                            style={props}
                            key={i}>
                            <PdfNameContainer>
                            <PdfName>
                              <PdfNameText>
                                {pdf.name}
                              </PdfNameText>

                                <LinkContainer key={i}>
                                  <LinkStyled
                                    to={pdf.href} target="_blank" download>Download</LinkStyled>
                                  &middot;
                                    <LinkStyled
                                      to={pdf.href} target="_blank">View</LinkStyled>
                                  </LinkContainer>
                                  </PdfName>
                            </PdfNameContainer>
                          </Card>
                        ))}
                      </>
                    )}
                  </CardContainer>
                      </div>
                      </SpringContainer>

        </Pages.Section>
          </MainContainer>

      </Pages.Lane>

    </Pages>
    </>
);
                  }

export default StudentPage;