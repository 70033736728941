import React, { useState } from "react";
import { css } from "styled-components";
import styled from "styled-components";
import Modal from "react-modal";
import truncate from "truncate";
import { useSpring } from "react-spring";
import LazyLoad from 'react-lazyload';

import Page from "../components/pages";
import Title from "../components/title";
import theme from "../theme/theme.js";

import Instructors from "../utils/instructors";
import IconClose from "../components/assets/icon-close";

const Instructor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;

  text-align: justify;

  width: auto;
  height: auto;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    text-align: justify;
    justify-content: center;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin-right: 10px;

  @media (max-width: 500px) {
    margin: 0;
  }
`;

const Overlay = styled.div`
  background: rgba(0,0,0, 0.5);
  background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%);

  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;

  @media (max-width: 500px) {
    object-fit: cover;
    width: 300px;
    height: 400;
  }
`;

const Photo = styled.img`
  width: 200px;
  height: 300px;

  @media (max-width: 500px) {
    object-fit: cover;
    width: 300px;
    height: auto;
  }
`;

const PhotoInModal = styled.img`
  width: 200px;
  height: 300px;
`;

const Name = styled.div`
  ${theme.FontFamilyBold}
  color: ${theme.PrimaryColorOnDark};
  text-align: center;
  text-transform: uppercase;

  position: absolute;
  bottom: 5px;
  width: 100%;
`;

const Bio = styled.div`
  box-sizing: border-box;
  padding: 5px;
  overflow: auto;

  width: 250px;
  height: 300px;

  @media (max-width: 500px) {
    width: 310px;
  }
`;

const ReadMore = styled.button`
  ${theme.AppearanceNone}
  ${theme.FontFamilyMedium};
  color: ${theme.PrimaryTextColor};

  text-decoration: underline;

  &:hover {
    color: ${theme.HighlightColor};
  }
`;

const FullBioContainer = styled.div`
  background-color: ${theme.PrimaryColorOnDark};
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 50px 30px;
  position: relative;
  margin: 0 auto;

  max-width: 700px;

`;

const FullBio = styled.div`
${theme.FontFamily}
text-align: justify;
`;

const ModalPhoto = styled.div`
  float: left;
  padding-right: 10px;
`;

const ButtonContainer = styled.div`
  color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;

`;

const CloseButton = styled.button`
  ${theme.AppearanceNone}
  padding: 5px;
`;


Modal.setAppElement("#root");
const InstructorPage = () => {

  const [activeInstuctor, setActiveInstructor] = useState(null);

  const props = useSpring({
    opacity: 1, from: {opacity: 0},
    delay: 200, duration: 500
  })

  return (
    <Page style={props}>
      <Page.Lane>
        <Page.Section>
        <Title>Instructors</Title>
          {Instructors.map((instructor, index) => {
            return (
              <Instructor key={index}>
                <ImageContainer>
                  <Overlay />
                  <LazyLoad height={300}>
                  <Photo src={instructor.photo} />
                  </LazyLoad>
                  <Name>{instructor.name}</Name>
                </ImageContainer>

                <Bio>{truncate(instructor.text, 350)}
                  <ReadMore onClick={() => setActiveInstructor(instructor)}>Read more</ReadMore>
                </Bio>
                      <Modal
                        isOpen={activeInstuctor}
                        onRequestClose={()=> setActiveInstructor(null)}
                        style={{
                        overlay: {
                          backgroundColor: "rgba(0,0,0, 0.3)",
                          overflow: "auto",
                          zIndex: 2,
                        },
                        content: {
                          border: 0,
                          top: "50%",
                          right: 0,
                          bottom: 0,
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "100%",
                          height: "100%",
                          background: "rgba(0,0,0,0)",
                          borderRadius: 0,
                          padding: 0,
                        }
                      }}>
                        {activeInstuctor && (
                        <FullBioContainer onClick={()=> setActiveInstructor(false)}>
                            <ButtonContainer>
                              <CloseButton onClick={()=> setActiveInstructor(false)}>
                                <IconClose />
                              </CloseButton>
                            </ButtonContainer>
                          <FullBio>
                            <ModalPhoto>
                              <PhotoInModal src={activeInstuctor.photo} />
                            </ModalPhoto>
                              {activeInstuctor.text}
                          </FullBio>
                        </FullBioContainer>

                        )}

                    </Modal>

              </Instructor>
            )
          })}
        </Page.Section>
      </Page.Lane>
    </Page>
  );
}

export default InstructorPage;