import React from "react";
import styled from "styled-components";
import { useSpring } from "react-spring";

import Pages from "../components/pages";
import Title from "../components/title";
import ToggleItem from "../components/toggle-item";


const faqs = [

  {
    question: "Is World Premiere Artists an Agency?",
    answer: "No, we are not an agency. World Premiere Artists is the premier Performing Arts Academy in California, Las Vegas, and Dallas. We offer classes in acting and modeling as well as social graces and etiquette. We do not provide jobs or castings, only training. Our California schools are licensed and bonded in accordance with all state laws.",
  },
  {
    question: "Does training make a difference?",
    answer: "All the difference in the world. The entertainment industry is fast-paced and constantly changing. Busy professionals vastly prefer a well-prepared candidate to one who lacks polish, experience, or training.",
  },
  {
    question: "Will this intefere with school?",
    answer: "No. We believe that a solid academic foundation should be a young persons first priority. World Premiere Artists policy is to work only with those who strive for academic excellence.",
  },
  {
    question: "Why do agents and industry professionals come to World Premiere Artists?",
    answer: "Agents, managers, and casting directors, meet current World Premiere Artists students for their new and upcoming projects. They know that World Premiere Artists produces industry educated clients. That applies to both the students and their parents. So when agents and managers are wanting to add to their client roster or book their next project, they will not be wasting their time interviewing hopefuls that do not have a skill set or parents with the wrong right mind set.",
  },
  {
    question: "Does it cost anything to train with World Premiere Artists? I have heard that if you want to be a model, agencies will pary everything for you to start. Is this true?",
    answer: "World Premiere Artists is not an agency; we are a Performing Arts Academy. There is no charge for the initial appointment/screening. This is a way for you to have all of your questions answered and see if you have potential to pursue the entertainment industry at a professional level with World Premiere Artists. The initial interview will only be an investment of your time.",
  },

]

const FaqContainer = styled.div`
  border: none;
  outline: none;
  text-align: center;
  padding-bottom: 30px;
`;

const FaqPage = () => {

  const props = useSpring({
    opacity: 1, from: {opacity: 0},
    delay: 200, duration: 500
  })

  return (
    <Pages style={props}>
      <Pages.Lane>
        <Pages.Section>
        <Title>Frequently Asked Questions</Title>
          <FaqContainer>
            {faqs.map((f, i) => {
              return (
                <ToggleItem question={f.question} answer={f.answer} key={i} />
              )
            })}
          </FaqContainer>

          <Pages.P>
            If you are selected, there are fees associated for our services. The fees will depend on what areas of the industry you have potential for success. We have many different workshops and there are different fees for different workshops.
        </Pages.P>
          <Pages.P>
            In any career, training and preparation is an up-front investment of time and money. The most successful models and talent will tell you that the learning process never ends. In today’s fast paced environment, very few agencies in the world will front costs necessary to begin a models career. There are “discoveries,” but like lottery winners, they are rare. All professionals have to work for their success. At World Class Modeling Talent Studios, we give you the training, guidance and confidence to help you attain your goals in the entertainment industry and in LIFE.
        </Pages.P>
          <Pages.P>
            If you still have questions for us please do not hesitate to contact us at 1-855-5ACTING (1-855-522-8464).
        </Pages.P>
            </Pages.Section>
      </Pages.Lane>
    </Pages>
  );
}

export default FaqPage;