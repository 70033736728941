import React from "react";

const SocialInsta = ({ className }) => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" preserveAspectRatio="xMidYMid meet" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <path d="M12,-5.00000752e-08 C5.37359618,-5.00000752e-08 -4.99999899e-08,5.37359618 -4.99999899e-08,11.9999999 C-4.99999899e-08,18.6264038 5.37359618,23.9999999 12,23.9999999 C18.6264038,23.9999999 24,18.6264038 24,11.9999999 C24,5.37359618 18.6264038,-5.00000752e-08 12,-5.00000752e-08 Z" fill="CurrentColor" fillRule="nonzero"></path>
        <path d="M18.8490601,14.8408814 C18.8157349,15.5744019 18.6990967,16.0751953 18.5288086,16.5135498 C18.1708374,17.439148 17.439148,18.1708374 16.5135498,18.5288086 C16.0753784,18.6990967 15.5744019,18.8155518 14.8410645,18.8490601 C14.1062622,18.8825684 13.871521,18.890625 12.0001831,18.890625 C10.1286621,18.890625 9.89410401,18.8825684 9.15911863,18.8490601 C8.42578124,18.8155518 7.92480467,18.6990967 7.48663329,18.5288086 C7.02667235,18.355774 6.61029049,18.0845948 6.26605221,17.7339478 C5.91558837,17.3898926 5.64440913,16.9733276 5.47137447,16.5135498 C5.30108641,16.0753784 5.18444819,15.5744019 5.15112302,14.8410645 C5.11724852,14.1060791 5.10937497,13.8713379 5.10937497,12 C5.10937497,10.1286621 5.11724852,9.89392087 5.15093992,9.15911863 C5.1842651,8.42559814 5.30072018,7.92480467 5.47100829,7.4864502 C5.64404294,7.02667235 5.91540522,6.6101074 6.26605221,6.26605221 C6.6101074,5.91540522 7.02667235,5.64422604 7.4864502,5.47119138 C7.92480467,5.30090332 8.42559814,5.18444819 9.15911863,5.15093992 C9.89392087,5.11743161 10.1286621,5.10937497 12,5.10937497 C13.8713379,5.10937497 14.1060791,5.11743161 14.8408814,5.15112302 C15.5744019,5.18444819 16.0751953,5.30090332 16.5135498,5.47100829 C16.9733276,5.64404294 17.3898926,5.91540522 17.7341309,6.26605221 C18.0845948,6.61029049 18.3559571,7.02667235 18.5288086,7.4864502 C18.6992798,7.92480467 18.8157349,8.42559814 18.8492432,9.15911863 C18.8827515,9.89392087 18.890625,10.1286621 18.890625,12 C18.890625,13.8713379 18.8827515,14.1060791 18.8490601,14.8408814 L18.8490601,14.8408814 Z" fill="#FFFFFF" fillRule="nonzero"></path>
        <path d="M17.3715821,7.93615723 C17.2611694,7.63696287 17.085022,7.36614987 16.8561401,7.14385985 C16.6338501,6.91497799 16.3632202,6.73883056 16.0638428,6.62841795 C15.8210449,6.53411862 15.4562988,6.42187498 14.7844849,6.39129635 C14.0577393,6.35815427 13.8398438,6.35101315 12,6.35101315 C10.1599731,6.35101315 9.94207762,6.35797118 9.21551511,6.39111326 C8.54370116,6.42187498 8.17877197,6.53411862 7.93615723,6.62841795 C7.63677978,6.73883056 7.36596678,6.91497799 7.14385985,7.14385985 C6.91497799,7.36614987 6.73883056,7.63677978 6.62823485,7.93615723 C6.53393552,8.17895506 6.42169188,8.54388425 6.39111326,9.21569821 C6.35797118,9.94226071 6.35083005,10.1601562 6.35083005,12.0001831 C6.35083005,13.8400269 6.35797118,14.0579224 6.39111326,14.784668 C6.42169188,15.4564819 6.53393552,15.821228 6.62823485,16.0640259 C6.73883056,16.3634034 6.9147949,16.6340332 7.14367676,16.8563232 C7.36596678,17.0852051 7.63659664,17.2613526 7.93597409,17.3717651 C8.17877197,17.4662476 8.54370116,17.5784912 9.21551511,17.6090698 C9.94207762,17.642212 10.1597901,17.6491699 11.9998169,17.6491699 C13.8400269,17.6491699 14.0579224,17.642212 14.7843018,17.6090698 C15.4561157,17.5784912 15.8210449,17.4662476 16.0638428,17.3717651 C16.6647949,17.1399536 17.1397706,16.664978 17.3715821,16.0640259 C17.4658814,15.821228 17.578125,15.4564819 17.6088867,14.784668 C17.6420288,14.0579224 17.6489869,13.8400269 17.6489869,12.0001831 C17.6489869,10.1601562 17.6420288,9.94226071 17.6088867,9.21569821 C17.5783081,8.54388425 17.4660645,8.17895506 17.3715821,7.93615723 L17.3715821,7.93615723 Z" fill="CurrentColor" fillRule="nonzero"></path>
        <path d="M12,15.5383301 C10.0457153,15.5383301 8.46148681,13.9542847 8.46148681,12 C8.46148681,10.0457153 10.0457153,8.46166991 12,8.46166991 C13.9541016,8.46166991 15.5383301,10.0457153 15.5383301,12 C15.5383301,13.9542847 13.9541016,15.5383301 12,15.5383301 Z" fill="#FFFFFF" fillRule="nonzero"></path>
        <path d="M14.296875,12 C14.296875,13.2685547 13.2685547,14.296875 12,14.296875 C10.7314453,14.296875 9.70312499,13.2685547 9.70312499,12 C9.70312499,10.7314453 10.7314453,9.70312499 12,9.70312499 C13.2685547,9.70312499 14.296875,10.7314453 14.296875,12 Z" fill="CurrentColor" fillRule="nonzero"></path>
        <path d="M15.6782227,9.14868163 C15.2215576,9.14868163 14.8513184,8.77844239 14.8513184,8.32177733 C14.8513184,7.86511226 15.2215576,7.49487303 15.6782227,7.49487303 C16.1348877,7.49487303 16.505127,7.86511226 16.505127,8.32177733 C16.5049439,8.77844239 16.1348877,9.14868163 15.6782227,9.14868163 Z" fill="#FFFFFF" fillRule="nonzero"></path>
    </g>
</svg>

);

export default SocialInsta;