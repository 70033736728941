import styled, { css } from "styled-components";
import theme from "../theme/theme";

const ButtonContact = styled.button`
  ${theme.Button}
  color: ${theme.HighlightColor};
  background-color: ${theme.PrimaryBgColor};

  &:hover {
    color: ${theme.PrimaryBgColor};
    background-color: ${theme.HighlightColor};
    border-color: ${theme.PrimaryBgColor};
  }

  ${props => props.$isContactPage && css`
    background-color: ${theme.HighlightColor};
    color: ${theme.PrimaryBgColor};

    &:hover {
    color: ${theme.HighlightColor};
    background-color: ${theme.PrimaryBgColor};
    border-color: ${theme.HighlightColor};
  }
  `}
`;

export default ButtonContact;