import React, { useState } from "react";
import styled from "styled-components";
import theme from "../theme/theme";
import ArrowDown from "./assets/arrow-down";

const SvgContainer = styled.div`
  box-sizing: border-box;
  float: right;
  margin: 5px;
  position: absolute;
    right:0;
    top:0;
  padding-right: 5px;

  width: 25px;
  height: 25px;
`;

const SvgUp = styled(ArrowDown)`
  transform: scaleY(-1);
`;

const ButtonFaq = styled.button`
  ${theme.AppearanceNone}
  border: 1px solid transparent;
  background-color: ${theme.HighlightColor};
  border-bottom: 1px solid ${theme.PrimaryBgColor};
  color: ${theme.PrimaryBgColor};

  padding: 5px 35px 5px 5px;
  position: relative;

  font-size: 1.2rem;
  font-weight: 700;
  text-align: justify;

  transition:
    border-color 250ms ease-in-out,
    background-color 250ms ease-in-out,
    color 250ms ease-in-out;

  outline: none;
  height: auto;
  min-height: 40px;
  width: 100%;

  &:hover {
    background-color: ${theme.PrimaryBgColor};
    color: ${theme.HighlightColor};
    border: 1px solid transparent;
    outline: none;

    ${SvgContainer} {
      color: ${theme.HighlightColor};
    }
  }
`;

const FaqText = styled.div`
background-color: #F3F3F3;
color: ${theme.PrimaryTextColor};

margin: 0 auto;

font-weight: 400;
padding: 30px 5px;
text-align: justify;

width: auto;
`;

const ToggleItem = ({ question, answer }) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <ButtonFaq onClick={() => setShow(!show)}>
        {question}
        <SvgContainer>
          {show ? <SvgUp /> : <ArrowDown />}
        </SvgContainer>
      </ButtonFaq>

      {/* if show is true, then display answer */}
      { show && <FaqText>{answer}</FaqText>}
    </div>
  )
};

export default ToggleItem;